import { format } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useTranslation } from 'react-i18next'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import JobInternalReferralsContainer from '~/components/DashboardView/JobInternalReferralsContainer'
import MyInterviewView from '~/components/DashboardView/MyInterviewView'
import MyJobView from '~/components/DashboardView/MyJobView'
import MyTasksView from '~/components/DashboardView/MyTasksView'
import { withLayoutGrid } from '~/components/Layout/LayoutGrid'
import SkeletonContainer from '~/components/Skeleton'
import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import { Button } from '~/core/ui/Button'
import { Checkbox } from '~/core/ui/Checkbox'
import { Container } from '~/core/ui/Container'
import Empty from '~/core/ui/Empty'
import If from '~/core/ui/If'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import QueryUpdateUserSettingDashboard from '~/lib/features/dashboard/graphql/mutation-update-user-setting-dashboard'
import { useQueryUserSettingDashboard } from '~/lib/features/dashboard/hooks/use-query-user-setting-dashboard'
import usePermissionSetting from '~/lib/features/permissions/hooks/use-permission-setting'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
const order = ['tasksInterviews', 'referrals', 'jobs']

const DashboardContainer = () => {
  const { t, i18n } = useTranslation()
  const { user } = useBoundStore()
  const { isFeatureEnabled, isUnLockFeature } = useSubscriptionPlan()
  const { actionAccessReferral } = usePermissionSetting()
  const [openDropdown, setOpenDropdown] = useState(false)
  const { trigger: updateUserSettingDashboard, isLoading: isUpdating } =
    useSubmitCommon(QueryUpdateUserSettingDashboard)
  const [showOptions, setShowOptions] = useState<
    {
      display: boolean
      field_name: string
      name: {
        en: string
        ja: string
      }
    }[]
  >()
  const {
    trigger: fetchUserSettingDashboard,
    data: dataUserSettingDashboard,
    isLoading
  } = useQueryUserSettingDashboard()
  const showComponent = (field_name: string) => {
    let value = showOptions?.filter((o) => o.field_name === field_name)?.[0]
    return value ? value.display : false
  }

  const showReferralOption = () => {
    return (
      isFeatureEnabled(PLAN_FEATURE_KEYS.referral) &&
      isUnLockFeature(PLAN_FEATURE_KEYS.referral)
    )
  }
  const onUpdateSetting = (field_name: string, display: boolean) => {
    if (isUpdating) return
    updateUserSettingDashboard({
      group: 'dashboard',
      values: dataUserSettingDashboard?.values?.map((o) => {
        if (o.field_name === field_name) return { ...o, display }
        return o
      })
    }).then((result) => {
      if (result.error) {
        return false
      }

      const { userSettingsDashboardUpdate } = result.data
      if (userSettingsDashboardUpdate?.group) {
      }
      return true
    })
  }

  const ref = useOnclickOutside(
    () => {
      setOpenDropdown(false)
    },
    {
      ignoreClass: 'my-ignore-action'
    }
  )

  const listOptions = useMemo(() => {
    return showOptions
      ?.filter((o) =>
        !showReferralOption() || !actionAccessReferral
          ? o.field_name !== 'referrals'
          : true
      )
      .sort((a, b) => order.indexOf(a.field_name) - order.indexOf(b.field_name))
  }, [showOptions, showReferralOption, actionAccessReferral])

  useEffect(() => {
    fetchUserSettingDashboard()
  }, [])

  useEffect(() => {
    if (dataUserSettingDashboard) {
      setShowOptions(dataUserSettingDashboard?.values || [])
    }
  }, [dataUserSettingDashboard])

  return (
    <SkeletonContainer
      showMoreLabel={`${t('common:infinity:showMore')}`}
      useLoading={false}
      isFirstLoading={isLoading || !dataUserSettingDashboard || !showOptions}
      className="px-6"
      renderCustomSkeleton={
        <Container overrideClass="px-6 space-y-4 pt-4">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
            <div key={index} className="mb-4 mt-4 flex items-center space-x-3">
              <div className="w-full space-y-1">
                <Skeleton className="h-4 w-full" />
              </div>
            </div>
          ))}
        </Container>
      }>
      <div className="">
        <div className="w-full pl-6">
          <div className="flex h-[56px] flex-row items-center pr-6">
            <TypographyText className="text-lg font-medium text-gray-900">
              {t('common:sidebar:management:list:dashboard')}
            </TypographyText>
            <div className="flex flex-1 items-center justify-end space-x-2">
              <span className="text-sm font-normal text-gray-700">
                {t('dashboard:hello')},{' '}
                <span className="text-sm font-medium">{user.fullName}</span>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="12"
                viewBox="0 0 2 12"
                fill="none">
                <path d="M1 0V12" stroke="#EDF0F4" />
              </svg>
              <div className="flex flex-row items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.91663 1.16683C9.91663 0.844663 9.65546 0.583496 9.33329 0.583496C9.01113 0.583496 8.74996 0.844663 8.74996 1.16683V1.75016H5.24996V1.16683C5.24996 0.844663 4.98879 0.583496 4.66663 0.583496C4.34446 0.583496 4.08329 0.844663 4.08329 1.16683V1.75016H2.91663C1.95013 1.75016 1.16663 2.53366 1.16663 3.50016V5.8335V11.6668C1.16663 12.6333 1.95013 13.4168 2.91663 13.4168H11.0833C12.0498 13.4168 12.8333 12.6333 12.8333 11.6668V5.8335V3.50016C12.8333 2.53366 12.0498 1.75016 11.0833 1.75016H9.91663V1.16683ZM11.6666 5.25016V3.50016C11.6666 3.178 11.4055 2.91683 11.0833 2.91683H9.91663V3.50016C9.91663 3.82233 9.65546 4.0835 9.33329 4.0835C9.01113 4.0835 8.74996 3.82233 8.74996 3.50016V2.91683H5.24996V3.50016C5.24996 3.82233 4.98879 4.0835 4.66663 4.0835C4.34446 4.0835 4.08329 3.82233 4.08329 3.50016V2.91683H2.91663C2.59446 2.91683 2.33329 3.178 2.33329 3.50016V5.25016H11.6666ZM2.33329 6.41683H11.6666V11.6668C11.6666 11.989 11.4055 12.2502 11.0833 12.2502H2.91663C2.59446 12.2502 2.33329 11.989 2.33329 11.6668V6.41683Z"
                    fill="#6B7280"
                  />
                </svg>
                <TypographyText className="text-sm font-normal text-gray-700">
                  {format(new Date(), 'MMM dd,yyyy')}
                </TypographyText>
              </div>
              <div ref={ref} className="relative ml-4">
                <Button
                  className="ml-2"
                  label={`${t('dashboard:configure')}`}
                  iconMenus="SlidersHorizontal"
                  size="xs"
                  type="secondary"
                  onClick={() => setOpenDropdown(!openDropdown)}
                />
                <div
                  className={`${
                    openDropdown ? '' : 'hidden'
                  } absolute right-0 z-50 mt-1 max-h-[560px] min-w-[192px] rounded bg-white p-1 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.05),0px_4px_6px_-2px_rgba(0,0,0,0.05),0px_10px_15px_-3px_rgba(0,0,0,0.10)]`}>
                  {listOptions?.map((option) => (
                    <div
                      className="h-8 rounded px-2 py-1.5 hover:bg-gray-50"
                      key={option.field_name}>
                      <Checkbox
                        size="sm"
                        onCheckedChange={(e) => {
                          const checked = e.target.checked
                          setShowOptions(
                            showOptions?.map((o) => {
                              if (o.field_name === option.field_name) {
                                o.display = checked
                                return { ...o, display: checked }
                              }
                              return o
                            })
                          )
                          onUpdateSetting(option.field_name, checked)
                        }}
                        isChecked={option.display}
                        text={
                          i18n.language === 'ja'
                            ? option.name.ja
                            : option.name.en
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isLoading &&
        showOptions?.filter((o) => o.display === true).length === 0 ? (
          <div className="flex h-[calc(100vh_-_100px)] flex-col space-y-4 px-6 pb-6">
            <If
              condition={
                !isLoading &&
                showOptions?.filter((o) => o.display === true).length === 0
              }>
              <Empty
                type="empty-data"
                title={`${t('dashboard:empty:noWidget')}`}
                description={`${t('dashboard:empty:description')}`}
                buttonTitle={undefined}
                onClick={() => {}}
              />
            </If>
          </div>
        ) : (
          <div className="flex h-fit flex-col space-y-4 px-6 pb-6">
            <If condition={showComponent('tasksInterviews')}>
              <div className="flex h-[320px] flex-row space-x-4 overflow-y-hidden">
                <div className="h-full max-h-fit flex-1 rounded border px-4 pt-3">
                  <MyTasksView />
                </div>
                <div className="h-full max-h-fit flex-1 rounded border pl-4 pt-3">
                  <MyInterviewView />
                </div>
              </div>
            </If>
            <If
              condition={
                actionAccessReferral &&
                showComponent('referrals') &&
                showReferralOption()
              }>
              <div className="h-[404px] overflow-y-hidden rounded border">
                <JobInternalReferralsContainer />
              </div>
            </If>
            <If condition={showComponent('jobs')}>
              <div className="h-[404px] overflow-y-hidden rounded border">
                <MyJobView />
              </div>
            </If>
          </div>
        )}
      </div>
    </SkeletonContainer>
  )
}

export default withLayoutGrid(withQueryClientProvider(DashboardContainer))
